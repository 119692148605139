"use client";

import { gtmSender, purify } from "@/helpers";
import Slider from '@mui/material/Slider';
import { styled } from "@mui/material";
import Image from "next/image";
import { formatNumber, formatPackageEventItem } from "@/helpers/formatters";
import { Fragment, useEffect, useRef, useState } from "react";
import PackageCard from "../PackageCard/PackageCard";
import { durationMinutes } from "@/helpers/fetchers/package";
import _e from "@/helpers/texts";

export default function PackDayGroup({ select, relatedPackage, heading, hidePackage }: {
  readonly select?: Function,
  readonly heading: Record<string,any>,
  readonly relatedPackage: Record<string,any>,
  readonly hidePackage?: boolean,
}) {
  const gtmRef = useRef<string>();
  const dailyRef = useRef<HTMLSpanElement|null>(null);

  const CalcSlider = styled(Slider)({
    color: '#6181f7',
    height: 8,
    '& .MuiSlider-rail': {
      height: 28,
      backgroundColor: 'rgba(200,200,200,.3)',
    },
    '& .MuiSlider-track': {
      border: 'none',
      height: 28,
      background: 'linear-gradient(270deg, #7fadf4 0%, #6181f7 100%)',
    },
    '& .MuiSlider-thumb': {
      height: 44,
      width: 44,
      background: 'linear-gradient(225deg, #7fadf4 0%, #6181f7 100%)',
      border: '2px solid rgba(255,255,255,.8)',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      background: 'transparent',
      padding: 0,
      fontFamily: 'inherit',
      fontSize: 40,
      fontWeight: 600,
      transform: 'translateY(120%) !important',
      '&::before': { display: 'none' },
      '&::after': {
        content: '"saat"',
        display: 'inline-block',
        marginLeft: 10,
      },
    },
  });

  const groups = [
    {
      'bar_color': '#FFBF00',
      'caption': relatedPackage?.caption ?? '',
      'id': 'group_' + (relatedPackage?.id ?? 0),
      'lessons': (relatedPackage?.curriculums?.curriculum ?? []).map((curriculum: Record<string,any>) => {
        return {
          'id': curriculum.curriculumId,
          'caption': curriculum.curriculumName,
          'lesson_duration': curriculum.totalContentDuration,
          'question_count': curriculum.totalItemCount,
          'info': curriculum?.info ?? {},
        };
      }),
      'total': {
        'lesson_duration': relatedPackage?.curriculums.summary.totalContentDuration ?? 0,
        'question_count': relatedPackage?.curriculums.summary.totalItemCount ?? 0,
      }
    }
  ];

  const defDaily = 6;

  const [ toggle, setToggle ] = useState(false);
  const [ daily, setDaily ] = useState<number>(defDaily);

  const handleSliderChange = (e: any, val: number|number[]) => {
    if(Array.isArray(val)) val = val[0];
    setDaily(Math.max(1, val));
  };

  const handleContinuousSliderChange = (e: any, val: number|number[]) => {
    if(dailyRef.current) dailyRef.current.innerText = val.toString();
  };

  const handleToggle = () => {
    setToggle((current) => {
      const nt = !current;
      if(!hidePackage && nt && relatedPackage?.id && gtmRef.current !== relatedPackage.id) {
        gtmSender({ ecommerce: null });
        gtmSender({
          "event": "view_item",
          "ecommerce": {
            "currency": "TRY",
            "items": [ formatPackageEventItem(relatedPackage) ]
          }
        });

        gtmRef.current = relatedPackage.id;
      }

      return nt;
    });

    refs.resultContainer.current!.style.height = toggle ? '0px' : refs.resultInner.current!.offsetHeight + 'px';
    refs.selectorContainer.current!.style.height = toggle ? refs.selectorInner.current!.offsetHeight + 'px' : '0px';

    const mainContainer = document?.querySelector('.__day-calc-list-top-container') as HTMLDivElement;
    if(mainContainer) document?.documentElement?.scrollTo({ top: mainContainer.offsetTop - 100, behavior: 'smooth' });
  };

  const refs = {
    selectorContainer: useRef<HTMLDivElement>(null),
    selectorInner: useRef<HTMLDivElement>(null),
    resultContainer: useRef<HTMLDivElement>(null),
    resultInner: useRef<HTMLDivElement>(null),
  };

  useEffect(() => {
    if(dailyRef.current) dailyRef.current.innerText = defDaily.toString();

    if(!refs.selectorContainer.current || !refs.resultContainer.current) return;
    refs.selectorContainer.current.style.height = refs.selectorContainer.current.offsetHeight + 'px';
    refs.resultContainer.current.style.height = refs.resultContainer.current.offsetHeight + 'px';
  }, [refs.resultContainer, refs.selectorContainer]);

  return (
    <div className="text-center">
      <div className="container !px-0 !pt-3 !pb-6 sm:!px-4 text-white">
        <h3 className={`text-2xl sm:text-[34px] [&>strong]:text-[34px] [&>b]:text-[34px] [&>strong]:font-bold [&>b]:font-bold font-light mb-3 leading-normal`} dangerouslySetInnerHTML={purify(heading.caption)}></h3>

        {/* Hour selector */}
        <div
          ref={refs.selectorContainer}
          className={`transition-all duration-300 ${toggle ? 'scale-0 opacity-0' : 'scale-100 opacity-100'}`}
        >
          <div ref={refs.selectorInner}>
            <div className={`text-[13px] sm:text-[18px] px-6 sm:px-0 mb-5 sm:mb-12 md:mb-16 leading-loose`} dangerouslySetInnerHTML={purify(heading.subcap)}></div>

            <div className="">
              <h3 className="-mb-5 sm:mb-5 text-center text-sm sm:text-[28px] font-semibold">
                { _e('Günde kaç saat ders çalışabilirsiniz?')}
              </h3>

              <div className="m-auto w-full max-w-[1112px] px-[22px] sm:px-14 pt-20 pb-6 sm:pb-20 mb-4 sm:mb-10 [&>.MuiSlider-root>.MuiSlider-thumb>.MuiSlider-valueLabel]:!hidden sm:[&>.MuiSlider-root>.MuiSlider-thumb>.MuiSlider-valueLabel]:!block">
                <div className="block sm:hidden text-[40px] font-semibold -mt-6 mb-6">
                  <span ref={dailyRef}></span> saat
                </div>

                <CalcSlider
                  defaultValue={daily}
                  step={1}
                  min={1}
                  max={12}
                  valueLabelDisplay="on"
                  onChangeCommitted={handleSliderChange}
                  onChange={handleContinuousSliderChange}
                />
              </div>
              <div className="text-center">
                {
                  select && (
                    <button className="btn btn-trans-white mx-1" onClick={ () => select() }>
                      { _e('Önceki') }
                    </button>
                  )
                }
                <button className="btn btn-red mx-1" onClick={handleToggle}>
                  { _e('Hesapla') }
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Result */}
        <div ref={refs.resultContainer} className={`transition-all duration-300 overflow-hidden ${toggle ? 'opacity-100' : 'opacity-0'}`} style={{ height: '0px' }}>
          <div ref={refs.resultInner} className="flex flex-col items-stretch">
            <div className={`grid ${groups.length > 1 ? 'grid-cols-1 lg:grid-cols-2' : ''} gap-7 mt-2`}>
              {
                groups?.map((group: Record<string,any>) => (
                  <div key={group.id} className="mt-2">
                    <div className="text-start my-1" dangerouslySetInnerHTML={{
                      __html: _e('Doping Hafıza ile günde <strong>:daily saat ders çalışarak</strong> :caption müfredatını <strong>:days günde</strong> bitirebilirsiniz.', {
                        'daily': daily,
                        'caption': group.caption,
                        'days': Math.ceil(group.total.lesson_duration / 1000 / 60 / 60 / daily)
                      })
                    }}></div>

                    <div className="flex -mx-4">
                      <div className="p-4 w-full">
                        <div className="rounded-xl overflow-hidden">
                          <div className="px-4 py-3 text-2xl text-start text-white font-bold" style={{ backgroundColor: group.bar_color ?? '#FFBF00' }}>
                            { group.caption }
                          </div>

                          <div className="grid grid-cols-[2fr,1fr,1fr] text-sm sm:text-base bg-slate-100">
                            <div className="px-4 py-2 flex items-center font-bold text-slate-500 whitespace-pre-wrap md:whitespace-nowrap">
                              <Image src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/public_assets/svg/ders-adi.svg`} className="hidden sm:block me-2" width={20} height={17} alt="Ders Adı" />
                              { _e('DERS ADI') }
                            </div>
                            <div className="px-2 py-2 flex justify-center items-center text-center font-bold text-[#8fbbac] whitespace-pre-wrap md:whitespace-nowrap">
                              <Image src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/public_assets/svg/ders-suresi.svg`} className="hidden sm:block me-2" width={20} height={20} alt="Ders Süresi" />
                              { _e('DERS SÜRESİ') }
                            </div>
                            <div className="px-2 py-2 flex justify-center items-center text-center font-bold text-slate-400 whitespace-pre-wrap md:whitespace-nowrap">
                              <Image src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/public_assets/svg/soru-sayisi.svg`} className="hidden sm:block me-2" width={20} height={20} alt="Soru Sayısı" />
                              { _e('SORU SAYISI') }
                            </div>
                          </div>

                          <div className="grid grid-cols-[2fr,1fr,1fr] text-[12px] sm:text-[13px] text-slate-500 font-semibold">
                            {
                              group.lessons?.map((lesson: Record<string,any>) => (
                                <Fragment key={lesson.id}>
                                  <div className="px-4 py-2 bg-white border-s-[1px] text-[13px] text-start">{lesson?.info?.caption ?? lesson.caption}</div>
                                  <div className="px-2 py-2 bg-white border-s-[1px] text-center text-[#8fbbac] whitespace-nowrap">{ formatNumber(durationMinutes(lesson.lesson_duration ?? 0)) } dk.</div>
                                  <div className="px-2 py-2 bg-white border-s-[1px] text-center ">{ lesson.question_count ? formatNumber(lesson.question_count) : '-' }</div>
                                </Fragment>
                              ))
                            }
                            
                            <div className="px-4 py-2 bg-white border-s-[1px] border-t-[3px] border-t-slate-400 text-[13px] text-start">{ _e('Toplam') }</div>
                            <div className="px-2 py-2 bg-white border-s-[1px] border-t-[3px] border-t-slate-400 text-center text-[#8fbbac] whitespace-nowrap">{ formatNumber(durationMinutes(group.total.lesson_duration ?? 0)) } dk.</div>
                            <div className="px-2 py-2 bg-white border-s-[1px] border-t-[3px] border-t-slate-400 text-center ">{ group.total.question_count ? formatNumber(group.total.question_count) : '-' }</div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>

            <div className={`mt-12 ${hidePackage ? 'mb-2' : 'mb-8'} text-center`}>
              {
                select && (
                  <button className="btn btn-trans-white m-1" onClick={ () => select() }>
                    { _e('Farklı Paket Seç') }
                  </button>
                )
              }
              <button className="btn btn-trans-red m-1" onClick={handleToggle}>{ _e('Tekrar Hesapla') }</button>
            </div>
            
            {
              relatedPackage && !hidePackage && (
                <div className="relative pt-2 w-full overflow-hidden text-start">
                  <PackageCard data={ relatedPackage } alwaysShowPricing={true} sizingFix={true} />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
