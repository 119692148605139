"use client";

import DPIcon from "../DPIcon/DPIcon";
import _e from "@/helpers/texts";
import { formatNumber } from "@/helpers/formatters";
import { addToCart } from "../CartLink/CartLink";
import { useRouter } from "next/navigation";
import { stripTags } from "@/helpers";
import { useRef } from "react";
import BuyInfoDialog from "../BuyInfoDialog/BuyInfoDialog";

export default function BuyNow({ price, cancelledPrice, external_id, className, ecommerceId, caption, categoryName, comingSoon }: {
  readonly price: number,
  readonly cancelledPrice?: number,
  readonly external_id: number,
  readonly className?: string,
  readonly ecommerceId: string,
  readonly caption: string,
  readonly categoryName: string,
  readonly comingSoon?: boolean,
}) {
  const dialogRef = useRef<any>(null);

  const router = useRouter();
  return (
    <>
      <BuyInfoDialog ref={dialogRef} />
      <div className={className}>
        <div className="flex flex-col items-stretch gap-4 md:flex-row md:items-center px-6 py-5 rounded-lg bg-slate-100">
          <div className="flex flex-col items-start">
            <div className="flex justify-start gap-4 items-baseline">
              {
                comingSoon ? (
                  <span className="text-honolulu-blue text-[22px] sm:text-[26px] md:text-[34px] font-bold">{ _e('Yakında Gelecek') }</span>
                ) : (
                  <>
                    <span className="text-honolulu-blue text-[22px] sm:text-[26px] md:text-[34px] font-bold">{ formatNumber(price) } TL</span>
                    {
                      cancelledPrice && (
                        <span className="text-lava text-[18px] sm:text-lg font-semibold line-through">{ formatNumber(cancelledPrice) } TL</span>
                      )
                    }
                  </>
                )
              }
            </div>
            <div className="text-price-success">
              {
                comingSoon ? (
                  <>
                    <DPIcon icon="warning" className="me-1" />
                    <span dangerouslySetInnerHTML={{ __html: _e('<strong>:name</strong> paketimiz yakında satışa sunulacaktır.', { name: stripTags(caption ?? '') }) }}></span>
                  </>
                ) : (
                  <>
                    <DPIcon icon="warning" className="me-1" />
                    { _e('Peşin Fiyatına Vade Farksız') } <strong>{ _e('Ayda :count TL', { count: formatNumber(price / 12) }) }</strong> { _e('Taksitle') }
                  </>
                )
              }
            </div>
          </div>
          
          {
            !comingSoon ? (
              <div className="flex justify-end flex-grow">
                <button className="btn btn-blue whitespace-nowrap w-full md:w-auto" data-ecommerce-id={ecommerceId} onClick={() => addToCart(external_id, ecommerceId, price, caption, categoryName, router)}>
                  { _e('Hemen satın al') }
                </button>
              </div>
            ) : (
              <div className="flex justify-end flex-grow">
                <button className="btn btn-blue whitespace-nowrap w-full md:w-auto" data-ecommerce-id={ecommerceId} onClick={() => { dialogRef?.current?.open(stripTags(caption ?? ''), stripTags(caption ?? '')); }}>
                  { _e('Bilgi Bırak') }
                </button>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}
