"use client";

import DPIcon from "@/components/DPIcon/DPIcon";
import styles from "./css/PackageItem.module.scss";
import { formatNumber, formatPackageEventItem, formatSlug, getECommerceId } from "@/helpers/formatters";
import _e from "@/helpers/texts";
import { useEffect, useState } from "react";
import Image from "next/image";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import { addToCart } from "../CartLink/CartLink";
import { useRouter } from "next/navigation";
import { gtmSender, linkUrl, stripTags } from "@/helpers";
import { productDynamicValues } from "@/helpers/fetchers/constants";
import { durationMinutes } from "@/helpers/fetchers/package";
import LoadingLink from "../LoadingLink/LoadingLink";

export default function PackageItem({ data, className, dialogRef }: {
  readonly data: any,
  readonly className?: string,
  readonly dialogRef: any,
}) {
  const router = useRouter();

  const [ fullSlug, setFullSlug ] = useState('');
  const [ highlights, setHighlights ] = useState<{ id: number, url: string, caption: string }[]>();

  const fallbackImage = `${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/webgorsel/tyt-tum-dersler.png`;

  const handleProductClick = () => {
    gtmSender({ ecommerce: null });
    gtmSender({
      event: "select_item",
      ecommerce: {
        items: [ formatPackageEventItem(data) ],
      },
    });
  };
  
  useEffect(() => {
    setFullSlug(formatSlug(data.slug, data?.slugPrefix));
    
    const highlightList: { id: number, url: string, caption: string }[] = [];
    const highlightCheck: string[] = [];
    let hlSelector = data?.properties; // Which list to select highlights from
    if(data?.thumbnails?.length) hlSelector = data?.thumbnails;
    
    hlSelector?.forEach((item: any) => {
      if(
        highlightList.length < 3
        && item?.bg_image_url
      ) {
        if(!highlightCheck.includes(item.bg_image_url)) {
          highlightCheck.push(item.bg_image_url);
          highlightList.push({ id: item.id, url: linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/attribute/', item.bg_image_url) ?? '', caption: item?.caption ?? '' });
        }
      }
    });

    setHighlights(highlightList);
  }, [
    data?.properties,
    data?.slugPrefix,
    data?.slug,
    data?.thumbnails,
  ]);

  return (
    <div className={`${styles['package-item']} ${className ?? ''}`}>

      <LoadingLink href={fullSlug} className={`${styles.card}`} onClick={ handleProductClick }>
        <div
          className={`${styles['card-image']} ${data?.horizontal_card_image_url ? '!h-auto !aspect-video !p-0' : ''} flex flex-col items-end justify-start gap-[5px]`}
          style={{ backgroundColor: data.image_bg_color ?? 'transparent' }}
        >
          {
            data?.horizontal_card_image_url ? (
              <>
                <div className={`relative w-full aspect-video`}>
                  <Image
                    src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data.horizontal_card_image_url) ?? ''}
                    alt={data.caption}
                    fill={true}
                    sizes="100%"
                    style={{ objectFit: 'cover', objectPosition: 'center' }}
                  />
                </div>

                <div className="absolute top-0 right-0 bottom-0 p-[5px] flex flex-col justify-between gap-[5px]">
                  {
                    highlights?.map((item: { id: number, url: string, caption: string }) => (
                      <div key={item.id} className={`${styles['card-image-badge']}`}>
                        <div className={styles.image}>
                          <Image
                            src={item.url}
                            alt={item.caption}
                            fill={true}
                            sizes="100%"
                            style={{ objectFit: 'contain', objectPosition: 'center' }}
                          />
                        </div>
                      </div>
                    ))
                  }
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${styles['bg-image']}`}
                  style={{ marginLeft: highlights?.length ? '0px' : 'auto', marginRight: 'auto' }}
                >
                  <ImageWithFallback
                    src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data?.card_image_url ?? 'tyt-tum-dersler.png') ?? ''}
                    fallback={fallbackImage}
                    alt={data.caption}
                    fill={true}
                    sizes="100%"
                    style={{ objectFit: (data?.card_image_url ?? '').split('.jp')?.length === 2 ? 'contain' : 'cover', objectPosition: 'center' }}
                  />
                </div>

                {
                  highlights?.map((item: { id: number, url: string, caption: string }) => (
                    <div key={item.id} className={`${styles['card-image-badge']}`}>
                      <div className={styles.image}>
                        <Image
                          src={item.url}
                          alt={item.caption}
                          fill={true}
                          sizes="100%"
                          style={{ objectFit: 'contain', objectPosition: 'center' }}
                        />
                      </div>
                    </div>
                  ))
                }
              </>
            )
          }
        </div>

        <div className={styles['card-body']}>
          <h3 className={styles.title}>{ data.caption }</h3>
          <div className={`${styles['card-info-badges']} ${data.card_tags?.length ? '' : 'hidden'}`}>
            {
              data.card_tags?.map((item: Record<string,any>, index: number) => (
                <span
                  key={item.id}
                  style={{
                    backgroundColor: item?.color ? item.color : ((index % 2) ? 'var(--honolulu-blue)' : 'var(--green-RYB)'),
                  }}
                >
                  { item?.caption }
                </span>
              ))
            }
          </div>
          <ul className={styles.info}>
            {
              (data?.card_props?.length ? data.card_props : data.properties)?.filter((item: Record<string,any>) => {
                if(item?.dynamic_value) {
                  if(
                    !Object.keys(productDynamicValues).includes(item.dynamic_value)
                    || !data?.curriculums?.summary?.[productDynamicValues?.[item.dynamic_value]]
                  ) return false;
                }

                return data?.card_props?.length || (item?.view_type && item.view_type !== 'Detay');
              })?.slice(0, Number(process.env.NEXT_PUBLIC_PRODUCT_CARD_MAX_ROWS ?? 6))?.map((item: Record<string,any>) => {
                let caption = item?.caption ?? '';
                if(
                  item?.dynamic_value
                  && Object.keys(productDynamicValues).includes(item.dynamic_value)
                  && data?.curriculums?.summary?.[productDynamicValues?.[item.dynamic_value]]
                ) {
                  let val = data.curriculums.summary?.[productDynamicValues[item.dynamic_value]] ?? 0;
                  if([ 'İçerik Süresi', 'İnteraktif İçerik Süresi' ].includes(item.dynamic_value)) {
                    val = formatNumber(durationMinutes(val as number)) + ' dk';
                  } else {
                    val = formatNumber(val as number);
                  }
                  
                  caption = val + (data?.card_props?.length ? ' ' + caption : '');
                } else if(!data?.card_props?.length && item?.special_button === 'Şimdi Anladım') return (
                  <li key={item.id} className="font-bold">
                    <DPIcon icon="star" className="me-1" />
                    { item.caption } { item?.subcap }
                  </li>
                );

                if(!data?.card_props?.length) return (
                  <li key={item.id}>
                    { caption } { item?.subcap }
                  </li>
                );
                
                return (
                  <li key={item.id} className={item?.bold ? 'font-bold' : 'font-normal'}>
                    {
                      item?.icon ? (
                        <DPIcon icon={item.icon} className="me-1" />
                      ) : (<></>)
                    }
                    { caption }
                  </li>
                );
              })
            }
          </ul>
        </div>

        <div className={styles.pricing}>
          {
            (data?.prevent_sales && data?.coming_soon) ? (
              <>
                <div>
                  <span className={styles.price}>{ _e('Yakında Gelecek') }</span>
                </div>
                <p className={styles.installments}>
                  <DPIcon icon="warning" className="me-1" />
                  <span dangerouslySetInnerHTML={{ __html: _e('<strong>:name</strong> paketimiz yakında satışa sunulacaktır.', { name: stripTags(data?.caption ?? '') }) }}></span>
                </p>
              </>
            ) : (
              <>
                <div>
                  <span className={styles.price}>{ formatNumber(data.price) } TL</span>
                  <span className={styles['old-price']}>{ data?.cancelled_price ? formatNumber(data.cancelled_price) + ' TL' : '' }</span>
                </div>
                <p className={styles.installments}>
                  <DPIcon icon="warning" className="me-1" />
                  { _e('Peşin Fiyatına Vade Farksız') } <strong>{ _e('Ayda :count TL', { count: formatNumber(data.price / 12) }) }</strong> { _e('Taksitle') }
                </p>
              </>
            )
          }
        </div>
      </LoadingLink>

      <div className={styles['action-buttons']}>
        <LoadingLink className={`${styles.btn} ${styles.details}`} href={fullSlug} onClick={ handleProductClick } title="Doping Hafıza TYT Tüm Dersler + AYT Eşit Ağırlık 2024">{ _e('İncele') }</LoadingLink>
        
        {/* This item should use data.external_id */}
        {
          (data?.prevent_sales && data?.coming_soon) ? (
            <button className={`${styles.btn} ${styles['buy-now']}`} data-ecommerce-id={getECommerceId(data) ?? '0'} onClick={() => { dialogRef?.current?.open(stripTags(data?.caption ?? ''), stripTags(data?.caption ?? '')); }}>{ _e('Bilgi Bırak') }</button>
          ) : (
            <button className={`${styles.btn} ${styles['buy-now']}`} data-ecommerce-id={getECommerceId(data) ?? '0'} onClick={() => addToCart(data.external_id, getECommerceId(data) ?? '0', data.price ?? 0, data.caption ?? '', data?.package_category?.caption ?? '', router)}>{ _e('Satın Al') }</button>
          )
        }
      </div>
    </div>
  );
}
