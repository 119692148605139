"use client";

import { gtmSender } from "@/helpers";
import styles from "../css/MostPreferredPackages.module.scss";

import PackageItem from "@/components/PackageItem/PackageItem";
import { formatPackageEventItem } from "@/helpers/formatters";
import _e from "@/helpers/texts";
import { useEffect, useRef } from "react";

export default function LoadingPackageList({ data, slugPrefix, loadMore, gtmListId, dialogRef }: {
  readonly data: Record<string,any>,
  readonly slugPrefix: string,
  readonly loadMore: Function,
  readonly gtmListId?: string,
  readonly dialogRef: any,
}) {
  const gtmRef = useRef<string>();

  useEffect(() => {
    const base = data?.show?.map((item: any) => item.id);
    const gtmStr = base.join(',');
    if(gtmRef.current !== gtmStr) {
      gtmSender({ ecommerce: null });
      gtmSender({
        "event": "view_item_list",
        "ecommerce": {
          "currency": "TRY",
          "item_list_id": gtmListId ?? 'package_list',
          "items": data?.show?.map((pkg: any) => formatPackageEventItem(pkg))
        }
      });
      gtmRef.current = gtmStr;
    }
  }, [ data?.show, gtmListId ]);

  return (
    <>
      <div className={`${styles['package-list-container']} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-7 gap-y-16 items-stretch mb-12`}>
        {
          data?.show?.map((item: any) => {
            return (
              <PackageItem key={item.id} dialogRef={dialogRef} data={ {slugPrefix: slugPrefix, ...item} } className="w-full" />
            );
          })
        }
      </div>

      <div className={`${styles['load-more']} ${data?.hasMore ? 'flex' : 'hidden'} justify-center items-center py-10`}>
        <button className="btn std-btn" onClick={() => loadMore((data?.currentPage ?? 0) + 1)}>{ _e('Daha Fazla Göster') }</button>
      </div>
    </>
  );
}
