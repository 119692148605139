"use client";

import { useEffect, useRef } from "react";

export default function TextMarquee({ className, text, duration }: { readonly className?: string, readonly text?: string, readonly duration?: number }) {
  const marqueeContainer = useRef<HTMLDivElement|null>(null);
  const marqueeInner = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    const marqueeSwitch = () => {
      if(!marqueeInner.current?.offsetLeft) {
        marqueeInner.current?.classList.remove('left-0');
        marqueeInner.current?.classList.add('left-[--marquee-diff]');
      } else {
        marqueeInner.current?.classList.remove('left-[--marquee-diff]');
        marqueeInner.current?.classList.add('left-0');
      }
    };

    const diff = (marqueeContainer.current?.clientWidth ?? 0) - (marqueeInner.current?.offsetWidth ?? 0);
    const md = Math.max(1, (duration ?? 10)) * Math.abs(diff);

    marqueeInner.current?.style?.setProperty('--marquee-diff', `${diff}px`);
    marqueeInner.current?.style?.setProperty('--marquee-duration', `${md}ms`);

    marqueeSwitch();
    
    const marqueeAnimation = setInterval(marqueeSwitch, md + 100);

    return () => { clearInterval(marqueeAnimation); };
  }, [ duration ]);

  return (
    <div className={`${className ?? ''}`}>
      <div ref={marqueeContainer} className={`relative overflow-hidden p-0 m-0`}>
        <div ref={marqueeInner} className="relative block whitespace-nowrap w-max left-0 transition-all ease-linear duration-[--marquee-duration]">{ text ?? '' }</div>
      </div>
    </div>
  );
}
