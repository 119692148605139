"use client";

import { useEffect, useRef } from "react";
import styles from "../css/MostPreferredPackages.module.scss";

import PackageItem from "@/components/PackageItem/PackageItem";
import { formatPackageEventItem } from "@/helpers/formatters";
import { gtmSender } from "@/helpers";

export default function FullPackageList({ data, slugPrefix, gtmListId, dialogRef }: {
  readonly data: Record<string,any>,
  readonly slugPrefix: string,
  readonly gtmListId?: string,
  readonly dialogRef: any,
}) {
  const gtmRef = useRef<string>();

  useEffect(() => {
    const base = data?.map((item: any) => item.id);
    const gtmStr = base.join(',');
    if(gtmRef.current !== gtmStr) {
      gtmSender({ ecommerce: null });
      gtmSender({
        "event": "view_item_list",
        "ecommerce": {
          "currency": "TRY",
          "item_list_id": gtmListId ?? 'package_list',
          "items": data?.map((pkg: any) => formatPackageEventItem(pkg))
        }
      });
      gtmRef.current = gtmStr;
    }
  }, [ data, gtmListId ]);

  return (
    <div className={`${styles['package-list-container']} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-7 gap-y-6 lg:gap-y-16 items-stretch mb-12`}>
      {
        data?.map((item: any) => {
          return (
            <PackageItem key={item.id} dialogRef={dialogRef} data={ {slugPrefix: slugPrefix, ...item} } className="w-full" />
          );
        })
      }
    </div>
  );
}
