"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../css/MostPreferredPackages.module.scss";
import "../css/MostPreferredPackagesSwiper.scss";

import 'swiper/css';
import 'swiper/css/navigation';
import PackageItem from "@/components/PackageItem/PackageItem";
import { useEffect, useRef } from "react";
import { formatPackageEventItem } from "@/helpers/formatters";
import { gtmSender } from "@/helpers";

export default function SwipingPackageList({ data, slugPrefix, gtmListId, dialogRef }: {
  readonly data: Record<string,any>,
  readonly slugPrefix?: string,
  readonly gtmListId?: string,
  readonly dialogRef: any,
}) {
  const gtmRef = useRef<string[]>([]);
  const swiperRef = useRef<any>();
  
  useEffect(() => {
    if(swiperRef?.current) swiperRef.current?.slideTo(0, 0);
  }, [data]);

  const handleSwipe = (swiper: any) => {
    const visible: Record<string,any>[] = [];
    swiper.visibleSlides?.forEach((slide: any) => {
      const item = data?.[slide.dataset?.itemId];
      if(item && !gtmRef.current?.includes(item?.id)) {
        visible.push(item);
        gtmRef.current?.push(item?.id);
      }
    });
    
    if(visible?.length) {
      gtmSender({ ecommerce: null });
      gtmSender({
        "event": "view_item_list",
        "ecommerce": {
          "currency": "TRY",
          "item_list_id": gtmListId ?? 'package_list',
          "items": visible.map((pkg: any) => formatPackageEventItem(pkg))
        }
      });
    }
  };

  return (
    <div className="w-full overflow-hidden">
      <Swiper
        loop={false}
        breakpoints={{
          0: {
            slidesPerView: 'auto',
          },
          640: {
            slidesPerView: 'auto',
          },
        }}
        watchSlidesProgress={true}
        className={`${styles['package-list-container']} !-mx-2`}
        onInit={ (swiper: any) => swiperRef.current = swiper }
        onAfterInit={handleSwipe}
        onSlideChange={handleSwipe}
      >
        {
          data?.map((item: any, index: number) => {
            return (
              <SwiperSlide
                key={item.id}
                data-item-id={index}
                className="!w-[calc(100%-28px)] sm:!w-[calc(50%-14px)]"
              >
                <PackageItem key={item.id} dialogRef={dialogRef} data={ {slugPrefix, ...item} } className="mx-2 flex-grow" />
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </div>
  );
}
