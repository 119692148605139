"use client";

import styles from "../css/MostPreferredPackages.module.scss";
import "../css/MostPreferredPackagesSwiper.scss";

import LoadingPackageList from "./LoadingPackageList";
import SwipingPackageList from "./SwipingPackageList";
import FullPackageList from "./FullPackageList";
import { useEffect, useState } from "react";

export default function PackageListSelector({ slugPrefix, noneSwiping, data, loadMoreHandler, rowsPerPage, gtmListId, dialogRef }: {
  readonly slugPrefix: string,
  readonly data: Record<string,any>,
  readonly loadMoreHandler: Function,
  readonly noneSwiping?: boolean,
  readonly rowsPerPage?: number,
  readonly gtmListId?: string,
  readonly dialogRef: any,
}) {
  const [ isMobile, setIsMobile ] = useState(false);
  const [ isLoaded, setIsLoaded ] = useState(false);

  const listenResize = () => {
    setIsMobile(window?.innerWidth < 1024);
  };

  useEffect(() => {
    listenResize();
    setIsLoaded(true);

    window?.addEventListener('resize', listenResize);
    return () => {
      window?.removeEventListener('resize', listenResize);
    };
  }, []);

  if(!isLoaded || !data?.packages) return (<></>);
  
  return (
    <div className={`${styles['package-list']} pt-8 ${isMobile ? '-mx-3' : ''} __package_list_view-package-list-swiper px-3 lg:px-0`}>
      {
        (() => {
          if(isMobile && !noneSwiping) return (
            <SwipingPackageList
              data={data.packages}
              slugPrefix={slugPrefix}
              gtmListId={gtmListId}
              dialogRef={dialogRef}
            />
          );
          
          if(isMobile || rowsPerPage === -1) {
            return (
              <FullPackageList
                data={data.packages}
                slugPrefix={slugPrefix}
                gtmListId={gtmListId}
                dialogRef={dialogRef}
              />
            );
          }

          return (
            <LoadingPackageList
              data={data}
              slugPrefix={slugPrefix}
              loadMore={loadMoreHandler}
              gtmListId={gtmListId}
              dialogRef={dialogRef}
            />
          );
        })()
      }
    </div>
  );
}
