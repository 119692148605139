
export const getGrid = (selector?: string, placement?: string) => {
  let grid = ['lg:w-6/12', 'lg:w-6/12'];
  if (selector === 'Geniş - Dar') grid = ['lg:w-7/12', 'lg:w-5/12'];
  if (selector === 'Dar - Geniş') grid = ['lg:w-5/12', 'lg:w-7/12'];

  const place = placement === 'Medya - Metin' ? 'lg:flex-row-reverse' : 'lg:flex-row';

  if (place === 'lg:flex-row-reverse') grid.reverse();

  grid.push(place);

  return grid;
};

export const videoFunctions = ({ videos, setVideos }: {
  readonly videos: { id: string, video: HTMLVideoElement }[],
  readonly setVideos: Function,
}) => {

  const fn = {
    playVideo: (videoId?: string|number) => {
      videos?.forEach((item: { id: string, video: HTMLVideoElement }) => {
        if(videoId === item.id ) item.video.play();
      });
    },

    stopVideo: (videoId?: string|number) => {
      videos?.forEach((item: { id: string, video: HTMLVideoElement }) => {
        if(item.video.paused ) return;
        if(videoId === item.id ) item.video.pause();
      });
    },

    stopVideos: (videoId?: string|number) => {
      videos?.forEach((item: { id: string, video: HTMLVideoElement }) => {
        if(item.video.paused ) return;
        if(videoId === item.id ) return;
        item.video.pause();
      });
    },

    addVideo: (video: HTMLVideoElement) => {
      setVideos((all: { id: string, video: HTMLVideoElement }[]) => {
        const current = all?.find((item: { id: string, video: HTMLVideoElement }) => item.id === video.id);
        if(! current ) all.push({ id: video.id, video: video });
        return all;
      });
    },

    removeVideo: (video: HTMLVideoElement) => {
      setVideos((all: { id: string, video: HTMLVideoElement }[]) => {
        all.forEach((item: { id: string, video: HTMLVideoElement }, index: number) => {
          if(item.id === video.id) all.splice(index, 1);
        });
        return all;
      });
    }
  };

  return fn;
};

export const findAndStopAllVideos = (except?: number|string) => {
  const allVideos = document?.querySelectorAll('video');
  allVideos?.forEach((item: HTMLVideoElement) => {
    if(item.id !== except) item.pause();
  });
};