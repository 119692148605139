/*
Shifts and returns "rpp * page" amount of items
from package list
*/
const shiftPackagePage = (res: Record<string,any>, page: number, rpp: number) => {
  const show: Record<string,any>[] = [];
  
  for(let i = 0; i < rpp * page; i++) {
    if(res?.length) {
      show.push(res.shift());
    }
  }
  
  return show;
};

/*
Handler for "Load More" button.
Shifts "rpp * page" amount of items from the package list
via shiftPackagePage()
and adds them to view
*/
export const loadMore = (page: number, rpp: number, selectedCategory: number, setPackageCategories: Function) => {
  setPackageCategories((current: Record<string,any>[]|Record<string,any>) => {
    const newCat = Array.isArray(current) ? [ ...current ] : [ { ...current } ];
    const res = [ ...newCat[selectedCategory].packages ];
    
    newCat[selectedCategory].show = shiftPackagePage(res, page, rpp);
    newCat[selectedCategory].currentPage = page;
    newCat[selectedCategory].hasMore = 
      newCat[selectedCategory].packages?.length
      && newCat[selectedCategory].show?.length < newCat[selectedCategory].packages.length;
    
    return Array.isArray(current) ? newCat : newCat[selectedCategory];
  });
};

/*
Initialize selectedCategory state
*/
export const initSelectedCategory = (packageData: Record<string,any>): number => {
  if(! packageData?.selectedCategory ) packageData.selectedCategory = 0;
  
  return packageData.selectedCategory;
};

/*
Initialize packageCategories state
*/
export const initPackageCategories = (data: Record<string,any>[], rpp: number): Record<string,any>[] => {
  return data.map((item: any) => {
    if(!item?.currentPage) item.currentPage = 1;

    const res = [ ...item.packages ];
    item.show = shiftPackagePage(res, item.currentPage, rpp);
    item.hasMore = item.packages?.length && item.show?.length < item.packages.length;

    return item;
  });
};

export const getRemainingDays = (date?: string): number|undefined => {
  if(!date) return undefined;

  const examDate = new Date(date);
  const today = new Date();

  const aDay = 1000 * 60 * 60 * 24;
  
  const diff = examDate.getTime() - today.getTime();
  
  if(diff <= aDay) return undefined;

  return Math.floor(diff / aDay);
};