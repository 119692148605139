"use client";

import { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import "./css/DayCalcList.scss";

import 'swiper/css';
import 'swiper/css/navigation';
import DPIcon from "@/components/DPIcon/DPIcon";
import { linkUrl, purify } from "@/helpers";
import PackDayGroup from "../PackDayCalc/PackDayGroup";

export default function DayCalcList({ list, heading, className }: {
  readonly list?: Record<string,any>[],
  readonly className?: string,
  readonly heading: {
    readonly caption: string,
    readonly subcap: string,
    readonly strongCap: string,
    readonly bgUrl: string,
  },
}) {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ selectedItem, setSelectedItem ] = useState<Record<string,any>|null>();

  const select = useCallback((id?: number) => {
    const items = [ ...(list ?? []) ];
    const selected = items.find((i: Record<string,any>) => i.id === id);

    if(!selected) {
      setSelectedItem(null);
    } else {
      selected.id = id;
      setSelectedItem(selected);
    }

    const mainContainer = document?.querySelector('.__day-calc-list-top-container') as HTMLDivElement;
    if(mainContainer) document?.documentElement?.scrollTo({ top: (list && list.length === 1) ? 0 : mainContainer.offsetTop - 100, behavior: 'smooth' });
  }, [ list ]);

  useEffect(() => {
    if(list && list.length === 1) select(list[0].id);

    setIsLoaded(true);
  }, [ list, select ]);

  return (
    <div
      className={`bg-charcoal mt-8 pt-12 pb-6 ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'} ${className ?? ''} __day-calc-list-top-container`}
      style={{ backgroundImage: 'url(' + linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/global/', heading.bgUrl) + ')' }}
    >
      <div className="__day-calc-list container text-white text-center">
        <div className={`${selectedItem ? 'opacity-0 h-0': 'opacity-100 h-auto py-3'} overflow-hidden transition-opacity duration-300 __day-calc-list-selector-container`}>
          <h3 className={`text-2xl sm:text-[34px] [&>strong]:text-[34px] [&>b]:text-[34px] font-light mb-3 leading-normal`} dangerouslySetInnerHTML={purify(heading.caption)}></h3>

          <div className={`text-[13px] sm:text-[18px] px-6 sm:px-0 mb-5 sm:mb-12 md:mb-16 leading-loose`} dangerouslySetInnerHTML={purify(heading.subcap)}></div>

          <div className={`text-[14px] sm:text-[22px] font-medium text-[#FFBD00]`} dangerouslySetInnerHTML={purify(heading.strongCap)}></div>

          <div className="-mx-3">
            <Swiper
              loop={false}
              navigation={{
                nextEl: '.swiper-button-calc-next',
                prevEl: '.swiper-button-calc-prev',
              }}
              modules={[Navigation]}
              slidesPerView={'auto'}
              className="mt-4 sm:mt-10 [&>.swiper-wrapper]:mx-auto [&>.swiper-wrapper]:w-fit"
            >
              {
                list?.map((item: Record<string,any>) => {
                  return (
                    <SwiperSlide
                      key={item.id}
                      className={`flex justify-stretch items-stretch my-1 mx-[6px] sm:mx-[2px] whitespace-nowrap px-2 py-5 rounded-md text-sm font-medium select-none !w-[148px] sm:!w-[160px] xl:!w-[176px] flex-grow-0 flex-shrink-0`}
                      onClick={ () => select(item.id) }
                    >
                      <div className="flex flex-col justify-center items-center rounded-2xl bg-[#0c005866] hover:bg-lava hover:-mt-3 transition-all duration-500 flex-grow text-white cursor-pointer p-7 sm:p-8 h-[132px] sm:h-[144px] xl:h-[160px]">
                        <span
                          className={`block w-[54px] h-[54px] bg-contain bg-no-repeat bg-center mb-1`}
                          style={{ backgroundImage: 'url(' + linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/hesap-sonuc/', item?.icon_url) + ')' }}
                        ></span>
                        <h5 className="text-sm mt-3 font-medium">
                          { item?.caption ?? item?.name }
                        </h5>
                      </div>
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>

            <div className="custom-buttons hidden md:flex mt-3 -mb-3 justify-center gap-4">
              <div className="swiper-button-calc-prev">
                <DPIcon className="text-[40px] text-slate-400 transition-all hover:text-white cursor-pointer" icon="arrow-circle-left" />
              </div>
              <div className="swiper-button-calc-next">
                <DPIcon className="text-[40px] text-slate-400 transition-all hover:text-white cursor-pointer" icon="arrow-circle-right" />
              </div>
            </div>
          </div>
        </div>
        
        <div className={`flex flex-col justify-around items-center overflow-hidden transition-opacity duration-300 ${selectedItem ? 'opacity-100 h-auto': 'opacity-0 h-0'} __day-calc-list-group-container`}>
          { selectedItem && (<PackDayGroup select={ (list?.length ?? 0) === 1 ? undefined : select } relatedPackage={selectedItem.package} heading={heading} />) }
        </div>
      </div>
    </div>
  );
}
