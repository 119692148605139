"use client";

import { PackageListData } from "@/types/page";
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import styles from "./css/MostPreferredPackages.module.scss";
import "./css/MostPreferredPackagesSwiper.scss";

import 'swiper/css';
import 'swiper/css/navigation';
import DPIcon from "@/components/DPIcon/DPIcon";
import { usePathname } from "next/navigation";
import { formatENetValues, formatSlug, insertENetValues } from "@/helpers/formatters";
import { loadMore, initSelectedCategory, initPackageCategories } from "./functions";
import PackageListSelector from "./PackageListSelector/PackageListSelector";
import JsonLd from "../JsonLd/JsonLd";
import { linkUrl } from "@/helpers";
import BuyInfoDialog from "../BuyInfoDialog/BuyInfoDialog";

/*
RowsPerPage should either be -1 or a multiple of 3
If not it will be converted to a multiple of 3
If no rowsPerPage value is sent, it will be considered as 3

Case rowsPerPage = -1: list all items, don'show "load more" button
Case else: list indicated amount of items and show a "load more" button if there are more items

NoneSwiping is a boolean that indicates if the package list should be swipable or not when view size is mobile
*/
export default function MostPreferredPackages({ packageData, rowsPerPage, noneSwiping, className, gtmListId }: {
  readonly packageData: PackageListData,
  readonly rowsPerPage?: number,
  readonly noneSwiping?: boolean,
  readonly className?: string,
  readonly gtmListId?: string,
}) {
  const dialogRef = useRef<any>(null);

  let allPackages: Record<string,any> = [];
  packageData.package_categories?.forEach((item: Record<string,any>) => { allPackages = allPackages.concat(item.packages); });
  
  // Convert rowsPerPage to a multiple of 3
  const rpp = Math.ceil(Math.max(rowsPerPage ?? 3) / 3) * 3;

  const pathname = usePathname();

  const data = { ...packageData };
  
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ selectedCategory, setSelectedCategory ] = useState(initSelectedCategory(packageData));
  const [ packageCategories, setPackageCategories] = useState(initPackageCategories(data.package_categories, rpp));

  const swiperRef = useRef<any>();
  
  const selectCategory = (index: number) => {
    if(swiperRef.current?.swiper) {
      let fix = Math.floor((swiperRef.current.swiper.visibleSlides.length - 1) / 2);
      let moveTo = Math.max(0, Math.min(swiperRef.current.swiper.slides.length - 1, index - fix));
      swiperRef.current.swiper.slideTo(moveTo);
    }

    packageData.selectedCategory = index;
    setSelectedCategory(index);
  };
  
  const loadMoreHandler = (page: number) => {
    loadMore(page, rpp, selectedCategory, setPackageCategories);
  };
  
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      <div className={`container ${styles['our-packages']} ${className ?? ''} ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'}`}>
        <h2 className={`${styles.heading} text-lg sm:text-2xl leading-[1.2] font-semibold`}>
          { data.our_packages_caption }
          <small className="text-[26px] sm:text-4xl leading-tight font-bold mt-2">{ data.our_packages_subcap }</small>
        </h2>

        <div className="__package_list_view-packages-swiper">
          <div className="custom-buttons hidden md:flex">
            <div className="swiper-button-prev most-preferred-packages-prev">
              <DPIcon className="text-slate-300 hover:text-sky-600 transition-all" icon="arrow-fill-circle-left" />
            </div>
            <div className="swiper-button-next most-preferred-packages-next">
              <DPIcon className="text-slate-300 hover:text-sky-600 transition-all" icon="arrow-fill-circle-right" />
            </div>
          </div>

          <Swiper
            ref={swiperRef}
            loop={false}
            navigation={{
              nextEl: '.most-preferred-packages-next',
              prevEl: '.most-preferred-packages-prev',
            }}
            modules={[Navigation]}
            watchSlidesProgress={true}
            slidesPerView={'auto'}
            className="mt-5 md:mt-10 mb-2"
          >
            {
              packageCategories.map((item: any, index: number) => {
                if(!item.packages?.length) return null;

                return (
                  <SwiperSlide
                    key={item.id}
                    className={`flex justify-center items-center my-1 mx-1 md:mx-2 [&:first-child]:ms-0 whitespace-nowrap px-7 py-3 rounded-[4px] text-sm font-medium cursor-pointer select-none w-full !flex-shrink-[100] bg-slate-200 hover:bg-opacity-80 transition-all ${selectedCategory === index ? 'active' : ''}`}
                    onClick={ () => selectCategory(index) }
                  >
                    { item?.caption }
                  </SwiperSlide>
                );
              })
            }
          </Swiper>
        </div>
        
        <PackageListSelector
          slugPrefix={formatSlug(packageCategories[selectedCategory]?.slug ?? '', packageData?.packages_slug ?? pathname)}
          noneSwiping={noneSwiping}
          data={packageCategories[selectedCategory]}
          loadMoreHandler={loadMoreHandler}
          rowsPerPage={rowsPerPage}
          gtmListId={gtmListId}
          dialogRef={dialogRef}
        />

        <JsonLd data={{
          "@context": "https://schema.org",
          "@type": "ProductCollection",
          "itemListElement": allPackages?.map((item: Record<string,any>, index: number) => {
            return {
              "@type": "Product",
              "position": index + 1,
              "name": item.caption,
              "image": linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', item.image_url),
              "description": insertENetValues(item?.content ?? '', formatENetValues({ ...(item?.curriculums?.credits ?? {}), ...{ Sezon: item.season, 'Paket Adı': item.caption } }), true),
              "brand": {
                "@type": "Brand",
                "name": "Doping Hafıza"
              },
              "offers": {
                "@type": "Offer",
                "priceCurrency": "TRY",
                "price": item.price,
                "url": formatSlug(item.slug, formatSlug(item?.package_category?.slug ?? '', packageData?.packages_slug ?? ''))
              }
            };
          })
        }} />
      </div>

      <BuyInfoDialog ref={dialogRef} />
    </>
  );
}
