"use client";

import DPIcon from "../DPIcon/DPIcon";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { linkUrl } from "@/helpers";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "./css/VideoDialogContent.scss";

export default function VideoDialog({ src, poster, dialogActive, deselectVideo }: {
  readonly src: string,
  readonly poster?: string,
  readonly dialogActive?: boolean,
  readonly deselectVideo: any,
}) {
  
  return (
    <Transition
      show={dialogActive ?? false}
      enter="transition duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as={Fragment}
    >
      <Dialog onClose={deselectVideo} className="z-[9999] fixed left-0 top-0 w-full h-screen">
        <Dialog.Panel>
          <div className={`__video-dialog`} tabIndex={0}>
            <div className="video-dialog-backdrop" onClick={deselectVideo}></div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="transform scale-50"
              enterTo="transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="transform scale-100"
              leaveTo="transform scale-50"
            >
              <div className="video-dialog-content">
                <div className="video-dialog-close" onClick={deselectVideo}>
                  <DPIcon icon="close" />
                </div>
                {
                  (() => {
                    return (
                      <VideoPlayer
                        src={ linkUrl(process.env.NEXT_PUBLIC_VIDEO_ROOT + '/ornek-videolar/', src) ?? '' }
                        poster={ poster ? linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/video-cover/', poster ?? '') ?? (process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/images/video-def.jpg' : (process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/images/video-def.jpg' }
                      />
                    );
                  })()
                }
              </div>
            </Transition.Child>
          </div>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  );
}
