"use client";

import { RefObject, useEffect, useId, useRef } from 'react';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';
import { findAndStopAllVideos } from '../HomeSlider/functions';

export default function VideoPlayer({ src, poster, onAdd, onRemove, onInteraction }: {
  readonly src: string,
  readonly poster?: string,
  readonly onAdd?: Function,
  readonly onRemove?: Function,
  readonly onInteraction?: Function,
}) {
  const videoId = useId();
  const videoRef: RefObject<HTMLVideoElement> = useRef(null);
  
  useEffect(() => {
    let plyr: any = null;
    const video: HTMLVideoElement|null|undefined = videoRef.current;
    if (!video) return;

    if(poster) {
      video.poster = poster;
    } else {
      if(src?.includes('media.m3u8')) video.poster = src.replace('media.m3u8', 'media_thumb.0000000.jpeg');
    }
    video.controls = true;

    const defaultOptions = {
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen'
      ],
      i18n: {
        restart: 'Tekrar başlat',
        rewind: 'Geri sar {seektime}s',
        play: 'Oynat',
        pause: 'Duraklat',
        fastForward: 'İleri sar {seektime}s',
        enterFullscreen: 'Tam ekran yap',
        exitFullscreen: 'Tam ekrandan çık',
        mute: 'Sessiz',
      },
      tooltips: {
        controls: true,
        seek: true
      }
    };

    let hls: any;

    if (video.canPlayType('application/vnd.apple.mpegurl')) {

      video.src = src;

    } else if (Hls.isSupported()) {

      hls = new Hls({ autoStartLoad: false });
      hls.loadSource(src);
      hls.attachMedia(video);
      
      const Plyr = require('plyr');
      plyr = new Plyr(video, defaultOptions);
      plyr.provider = 'hls';
      
    }

    const handlePlay = () => {
      if(onInteraction) onInteraction(videoId, 'play');
      findAndStopAllVideos(videoId);
      if(hls) hls.startLoad();
    };
    
    const handlePause = () => {
      if(onInteraction) onInteraction(videoId, 'pause');
      if(hls) hls.stopLoad();
    };

    video.addEventListener('play', handlePlay);
    video.addEventListener('pause', handlePause);

    if(onAdd) onAdd(video);

    /* Observer for leaving screen */
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if(!entry.isIntersecting) {
          video.pause();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.6,
    });

    if(video) observer.observe(video);

    const videoControls = document?.querySelectorAll('.plyr__controls:not(.swiper-no-swiping)');
    videoControls?.forEach((item: any) => {
      item?.classList?.add('swiper-no-swiping');
    });
    
    return () => {
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('pause', handlePause);

      observer.disconnect();

      if(onRemove) onRemove(video);
    };
  }, [
    onAdd,
    onRemove,
    onInteraction,
    poster,
    src,
    videoId
  ]);

  let vSrc = src.split('/');
  let vTitle = src;
  if(vSrc.length >= 2) vTitle = vSrc[vSrc.length - 2];

  return (
    <video id={videoId} ref={videoRef} title={vTitle} />
  );
}